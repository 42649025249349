@import url(https://fonts.googleapis.com/css2?family=Dongle:wght@300;400;700&family=Nanum+Gothic:wght@400;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Main-container {
  height: 100vh;
}

.Main {
  height: 99vh;
  width: 100%;
}

@font-face {
  font-family: "GmarketSansTTFBold";
  src: url(/static/media/GmarketSansTTFBold.69428064.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "GongGothicBold";
  src: url("/static/media/Gong Gothic Bold.7b9e1d2a.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "GongGothicLight";
  src: url("/static/media/Gong Gothic Light.edb6fe4d.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "GongGothicMedium";
  src: url("/static/media/Gong Gothic Medium.0b775ef1.ttf") format("truetype");
  font-weight: normal;
}

/* .tt {
  font-size: 30px;
  font-family: "GmarketSansTTFBold", serif;
} */

.mintPage_main-container {
  width: 100%;
  min-height: 1000px;
  min-width: 1300px;

  background-color: #01290e;
}

.mintPage_main-header {
  /* background-color: white; */
}

.mintPage_main-header-container {
  display: flex;
  justify-content: space-between;
}

.mintPage_main-header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin: 0px 20px; */
}

.mintPage_main-header-content:last-child {
  margin-right: 40px;
}
.mintPage_main-header-logo {
  margin-left: 20px;
  width: 150px;
}

.mintPage_main-header-logo img {
  width: 150px;
}

.mintPage_main-body {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
/* .mintPage_main-subject-container {
  margin: 20px;
  display: flex;
  justify-content: center;
}
.mintPage_main-subject span {
  font-size: 50px;
  opacity: 0.95;
} */

.mintPage_main_left-container {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  /* background-color: aliceblue; */
}

.mintPage_main_left-header {
  display: flex;
  justify-content: center;
}
.mintPage_main_left-header img {
  width: 400px;
}

.mintPage_main_left-content {
  /* background-color: azure; */
}
.mintPage_main_left-content img {
  width: 600px;
  border-radius: 20px;
  border: 2px solid yellowgreen;
}
.mintPage_main {
  width: 500px;
  background-color: beige;
  padding: 20px;
  border-radius: 20px;
}

.mintPage_main-content {
  width: 100%;
  padding: 0px 20px;
}
.mintPage_main-content:nth-child(2) {
  padding-top: 20px;
}

.mintPage_main-content:nth-child(3) {
  margin: 35px 0px;
}

.mintPage_main-content:nth-child(4) {
  margin: 45px 0px;
}

.mintPage_title-container {
  /* display: flex;
  justify-content: center; */
  color: black;
  padding-top: 15px;
}

.mintPage_main-content-round {
  font-family: "GongGothicMedium";
  font-size: 20px;
  /* background-color: azure; */
  margin-bottom: 2px;
  opacity: 0.9;
  font-weight: 700;
}
.mintPage_main-content-round-maxMintInfo {
  font-size: 14px;
  opacity: 0.6;
  font-weight: 700;
  margin-bottom: 10px;
}

.mintPage_main-content-title {
  /* background-color: aqua; */
  font-family: "GongGothicBold";
  font-size: 45px;
  font-weight: 800;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.mintPage_blockHeight-container {
  display: flex;
}

.mintPage_blockHeight-left-container {
  width: 50%;
}

.mintPage_blockHeight-right-container {
  width: 50%;
}

.mintPage_main-content_mintAmount-container {
  display: flex;
  justify-content: center;
}

.mintPage_mintAmount-amount {
  font-family: "GongGothicBold";
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 800;
}

.mintAmountButton {
  width: 40px;
  /* font-size: 10px; */
  font-weight: 800;
  background-color: green;
}

.mintPage_blockHeight-title {
  font-size: 15px;
  opacity: 0.8;
  margin-bottom: 2px;
  font-weight: 500;
}

.mintPage_blockHeight-number {
  font-family: "GongGothicMedium";
  font-size: 20px;
  font-weight: 800;
}
.mingPage_blockHeight-startBlock {
  color: green;
}

.blockHeight-description {
  margin-top: 20px;
  opacity: 0.7;
  font-size: 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  padding-bottom: 20px;
}

.mintPage_main-content_count-title {
  font-family: "GongGothicMedium";
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0px 5px;
  font-weight: 800;
}

.mintPage_mintButton-container {
  display: flex;
  justify-content: center;
}

.mintButton {
  width: 100%;
  height: 80px;
  background-color: green;
}

.mintPage_main_left-content {
  width: 600px;
}
.mintPage_main_left-content_description-box {
  background-color: #0000004a;
  color: white;
  /* #FBA026 노랑 */
  margin-top: 20px;
  border-radius: 15px;
  padding: 20px 40px;

  border: 2px solid yellowgreen;
}
.mintPage_main_left-content_description-text {
  margin-top: 25px;
  font-size: 20px;
  font-weight: 800;
}

.mintPage_main_left-content_description-text:last-child {
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 800;
  color: yellowgreen;
}

.progress {
  background-color: rgba(53, 104, 61, 0.103);
}

.blockHeight-showTime {
  margin-top: 2px;
  opacity: 0.6;
  font-size: 14px;
}

.adminContent {
  width: 800px;
  display: flex;
  justify-content: space-between;
}

.Admin_Button {
  margin-left: 10px;
}

.addWhitelists {
  width: 500px;
  height: 300px;
}

.checkWhiteList_input {
  width: 410px;
  border: 2px solid #00000029;
  border-radius: 5px;
  padding: 5px;
}

.checkWhiteList-title {
  margin-bottom: 7px;
  font-size: 25px;
}

.input-address-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  padding: 60px 0px;
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.input-address-container-box {
  width: 80%;
}

.input-address-content {
  display: flex;
  justify-content: center;
}

.input-address-content-checkBox {
  margin-top: 10px;
  height: 40px;
}

.input-address-content-checkBox input {
  width: 50px;
}

input {
  accent-color: green;
}

.wallet-connect-button {
  width: 200px;
  height: 50px;
  background-color: green;
}

.WalletSelect-content {
  display: flex;
  padding: 20px;
  margin: 10px 0px;
  width: 100%;
  border-radius: 15px;
}

.WalletSelect-content:hover {
  cursor: pointer;
  background-color: rgba(128, 128, 128, 0.1);
}

.WalletSelect-contect-icon {
  width: 50px;
}

.WalletSelect-contect-icon img {
  width: 40px;
}

.WalletSelect-contect-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "GmarketSansTTFBold", serif;
  margin-left: 15px;
}

.verifyNFT_main-container {
  width: 100%;
  min-height: 1000px;
  background-color: beige;
  /* //#01290e; */
}
.verifyNFT_sub-container {
  /* min-width: 1000px; */
}

.verifyNFT-container {
  min-height: 500px;
}

.verifyNFT_nfts-container {
  width: 100%;
  min-height: 300px;
}

.verifyNFT_buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.verifyNFT_button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.image-main-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* padding-left: 80px; */
}

.image-card {
  display: inline-block;
  width: 200px;

  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;

  text-align: center;
  border-radius: 20px;
  border: 1px solid green;
}

.image-card img {
  border-radius: 20px;
  max-width: 100%;
  max-height: 100%;
}

.token-info {
  margin-top: 5px;
  font-weight: 700;
  font-size: 22px;
  color: green;
}

.verifyBamboo-main-container {
  display: flex;
  /* margin: 100px 200px; */
  /* margin: 50px 0px; */
  flex-direction: column;
  /* background-color: beige; */
  padding: 30px 0px;
  border: 1px solid #e5e5e5;
  padding-bottom: 50px;
}

.verifyBamboo-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.verifyBamboo-registerWalletButton {
  width: 200px;
  height: 50px;
  background-color: green;
}

.verifyBamboo-registerWalletButton:hover {
  cursor: pointer;
  /* background-color: rgba(128, 128, 128, 0.1); */
}
.verifyBamboo-container_title span {
  font-size: 50px;
}

.verifyBamboo-container_description-container {
  /* background-color: aqua; */
  margin-top: 30px;
}

.verifyBamboo-container_description {
  margin-top: 15px;
}

.verifyBamboo-container_description span {
  font-size: 25px;
}

.verifyBamboo-container_button-container {
  margin-top: 50px;
  /* background-color: blueviolet; */
  display: flex;
  justify-content: space-between;
  width: 450px;
}

.verifyBamboo-container_registeredAddress-container {
  margin-top: 40px;
  /* background-color: aqua; */
  width: 800px;
  display: flex;
  justify-content: center;
}

.verifyBamboo-container_registeredAddress-container span {
  font-size: 22px;
}

.App {
  /* font-family: "GmarketSansTTFBold", serif; */
  font-family: "GongGothicLight", sans-serif;
}

