@font-face {
  font-family: "GmarketSansTTFBold";
  src: url("../fonts/GmarketSansTTFBold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "GongGothicBold";
  src: url("../fonts/Gong\ Gothic\ Bold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "GongGothicLight";
  src: url("../fonts/Gong\ Gothic\ Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "GongGothicMedium";
  src: url("../fonts/Gong\ Gothic\ Medium.ttf") format("truetype");
  font-weight: normal;
}

/* .tt {
  font-size: 30px;
  font-family: "GmarketSansTTFBold", serif;
} */

.mintPage_main-container {
  width: 100%;
  min-height: 1000px;
  min-width: 1300px;

  background-color: #01290e;
}

.mintPage_main-header {
  /* background-color: white; */
}

.mintPage_main-header-container {
  display: flex;
  justify-content: space-between;
}

.mintPage_main-header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin: 0px 20px; */
}

.mintPage_main-header-content:last-child {
  margin-right: 40px;
}
.mintPage_main-header-logo {
  margin-left: 20px;
  width: 150px;
}

.mintPage_main-header-logo img {
  width: 150px;
}

.mintPage_main-body {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
/* .mintPage_main-subject-container {
  margin: 20px;
  display: flex;
  justify-content: center;
}
.mintPage_main-subject span {
  font-size: 50px;
  opacity: 0.95;
} */

.mintPage_main_left-container {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  /* background-color: aliceblue; */
}

.mintPage_main_left-header {
  display: flex;
  justify-content: center;
}
.mintPage_main_left-header img {
  width: 400px;
}

.mintPage_main_left-content {
  /* background-color: azure; */
}
.mintPage_main_left-content img {
  width: 600px;
  border-radius: 20px;
  border: 2px solid yellowgreen;
}
.mintPage_main {
  width: 500px;
  background-color: beige;
  padding: 20px;
  border-radius: 20px;
}

.mintPage_main-content {
  width: 100%;
  padding: 0px 20px;
}
.mintPage_main-content:nth-child(2) {
  padding-top: 20px;
}

.mintPage_main-content:nth-child(3) {
  margin: 35px 0px;
}

.mintPage_main-content:nth-child(4) {
  margin: 45px 0px;
}

.mintPage_title-container {
  /* display: flex;
  justify-content: center; */
  color: black;
  padding-top: 15px;
}

.mintPage_main-content-round {
  font-family: "GongGothicMedium";
  font-size: 20px;
  /* background-color: azure; */
  margin-bottom: 2px;
  opacity: 0.9;
  font-weight: 700;
}
.mintPage_main-content-round-maxMintInfo {
  font-size: 14px;
  opacity: 0.6;
  font-weight: 700;
  margin-bottom: 10px;
}

.mintPage_main-content-title {
  /* background-color: aqua; */
  font-family: "GongGothicBold";
  font-size: 45px;
  font-weight: 800;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.mintPage_blockHeight-container {
  display: flex;
}

.mintPage_blockHeight-left-container {
  width: 50%;
}

.mintPage_blockHeight-right-container {
  width: 50%;
}

.mintPage_main-content_mintAmount-container {
  display: flex;
  justify-content: center;
}

.mintPage_mintAmount-amount {
  font-family: "GongGothicBold";
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 800;
}

.mintAmountButton {
  width: 40px;
  /* font-size: 10px; */
  font-weight: 800;
  background-color: green;
}

.mintPage_blockHeight-title {
  font-size: 15px;
  opacity: 0.8;
  margin-bottom: 2px;
  font-weight: 500;
}

.mintPage_blockHeight-number {
  font-family: "GongGothicMedium";
  font-size: 20px;
  font-weight: 800;
}
.mingPage_blockHeight-startBlock {
  color: green;
}

.blockHeight-description {
  margin-top: 20px;
  opacity: 0.7;
  font-size: 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  padding-bottom: 20px;
}

.mintPage_main-content_count-title {
  font-family: "GongGothicMedium";
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0px 5px;
  font-weight: 800;
}

.mintPage_mintButton-container {
  display: flex;
  justify-content: center;
}

.mintButton {
  width: 100%;
  height: 80px;
  background-color: green;
}

.mintPage_main_left-content {
  width: 600px;
}
.mintPage_main_left-content_description-box {
  background-color: #0000004a;
  color: white;
  /* #FBA026 노랑 */
  margin-top: 20px;
  border-radius: 15px;
  padding: 20px 40px;

  border: 2px solid yellowgreen;
}
.mintPage_main_left-content_description-text {
  margin-top: 25px;
  font-size: 20px;
  font-weight: 800;
}

.mintPage_main_left-content_description-text:last-child {
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 800;
  color: yellowgreen;
}

.progress {
  background-color: rgba(53, 104, 61, 0.103);
}

.blockHeight-showTime {
  margin-top: 2px;
  opacity: 0.6;
  font-size: 14px;
}

.adminContent {
  width: 800px;
  display: flex;
  justify-content: space-between;
}

.Admin_Button {
  margin-left: 10px;
}

.addWhitelists {
  width: 500px;
  height: 300px;
}

.checkWhiteList_input {
  width: 410px;
  border: 2px solid #00000029;
  border-radius: 5px;
  padding: 5px;
}

.checkWhiteList-title {
  margin-bottom: 7px;
  font-size: 25px;
}

.input-address-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  padding: 60px 0px;
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.input-address-container-box {
  width: 80%;
}

.input-address-content {
  display: flex;
  justify-content: center;
}

.input-address-content-checkBox {
  margin-top: 10px;
  height: 40px;
}

.input-address-content-checkBox input {
  width: 50px;
}

input {
  accent-color: green;
}
