.verifyBamboo-main-container {
  display: flex;
  /* margin: 100px 200px; */
  /* margin: 50px 0px; */
  flex-direction: column;
  /* background-color: beige; */
  padding: 30px 0px;
  border: 1px solid #e5e5e5;
  padding-bottom: 50px;
}

.verifyBamboo-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.verifyBamboo-registerWalletButton {
  width: 200px;
  height: 50px;
  background-color: green;
}

.verifyBamboo-registerWalletButton:hover {
  cursor: pointer;
  /* background-color: rgba(128, 128, 128, 0.1); */
}
.verifyBamboo-container_title span {
  font-size: 50px;
}

.verifyBamboo-container_description-container {
  /* background-color: aqua; */
  margin-top: 30px;
}

.verifyBamboo-container_description {
  margin-top: 15px;
}

.verifyBamboo-container_description span {
  font-size: 25px;
}

.verifyBamboo-container_button-container {
  margin-top: 50px;
  /* background-color: blueviolet; */
  display: flex;
  justify-content: space-between;
  width: 450px;
}

.verifyBamboo-container_registeredAddress-container {
  margin-top: 40px;
  /* background-color: aqua; */
  width: 800px;
  display: flex;
  justify-content: center;
}

.verifyBamboo-container_registeredAddress-container span {
  font-size: 22px;
}
