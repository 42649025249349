.verifyNFT_main-container {
  width: 100%;
  min-height: 1000px;
  background-color: beige;
  /* //#01290e; */
}
.verifyNFT_sub-container {
  /* min-width: 1000px; */
}

.verifyNFT-container {
  min-height: 500px;
}

.verifyNFT_nfts-container {
  width: 100%;
  min-height: 300px;
}

.verifyNFT_buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.verifyNFT_button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.image-main-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* padding-left: 80px; */
}

.image-card {
  display: inline-block;
  width: 200px;

  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;

  text-align: center;
  border-radius: 20px;
  border: 1px solid green;
}

.image-card img {
  border-radius: 20px;
  max-width: 100%;
  max-height: 100%;
}

.token-info {
  margin-top: 5px;
  font-weight: 700;
  font-size: 22px;
  color: green;
}
